import type { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useRouter } from 'next/router';
import { useState, useCallback, useRef } from 'react';

import { useAuth } from '@/engine/hooks/useAuth';
import { loginSchema } from '@/modules/schemas/login';
import { getYupErrors } from '@/modules/utils/yup';
import { Link } from '@/screen/components/forward/Link';
import { Button } from '@/screen/components/ui/Button';
import { Input } from '@/screen/components/ui/Input';
import { Spinner } from '@/screen/components/ui/Spinner';

export const Main = (): JSX.Element => {
  const router = useRouter();
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();

  const [loading, updateLoading] = useState(false);
  const [submitError, updateSubmitError] = useState('');

  const handleSubmit = useCallback(
    async (formData: Record<'email' | 'password', string>) => {
      if (loading) {
        return;
      }

      try {
        updateSubmitError('');
        updateLoading(true);
        formRef.current?.setErrors({});

        const dataIsValid = await loginSchema.isValid(formData);

        if (!dataIsValid) {
          formRef.current?.setErrors(await getYupErrors(loginSchema, formData));
          return;
        }

        const { success, error } = await signIn(formData);

        if (!success || error) {
          updateSubmitError(
            error ?? 'Ocorreu um erro inesperado, tente novamente ><',
          );
          return;
        }

        router.push('/');
      } finally {
        updateLoading(false);
      }
    },
    [loading, router, signIn],
  );

  return (
    <div className="flex my-auto">
      <div className="hidden w-2/5 h-full min-h-screen bg-gradient-to-r from-floor-green-100 to-floor-green md:block" />

      <div className="w-full flex spacing-x flex-grow items-center justify-center flex-shrink-0 py-24 md:justify-start md:w-3/5">
        <section className="flex flex-col max-w-wai w-full">
          <Link href="/" className="text-blue-500">
            Voltar
          </Link>

          <h1 className="font-bold text-4xl mt-1">Bem vindo!</h1>

          {submitError && <p className="mt-2 text-red-500">{submitError}</p>}

          <Form onSubmit={handleSubmit} ref={formRef} className="mt-8">
            <h4 className="font-semibold">E-mail</h4>
            <Input name="email" className="mt-1" />

            <h4 className="mt-6 font-semibold">Senha</h4>
            <Input name="password" className="mt-1" type="password" />

            <Link
              href="/forgot-password"
              className="block text-sm text-blue-400 mt-1"
            >
              Esquecia minha senha
            </Link>

            <Button
              type="submit"
              className="flex items-center justify-center mt-8"
            >
              {loading ? (
                <Spinner color="floor-white" className="w-8 h-8" />
              ) : (
                'Entrar'
              )}
            </Button>
          </Form>
        </section>
      </div>
    </div>
  );
};
