import { ValidationError, AnySchema } from 'yup';

export function parseYupError(error: ValidationError): Record<string, string> {
  return error.inner.reduce(
    (obj, field) =>
      Object.assign(obj, {
        [field.path as string]: field.message,
      }),
    {},
  );
}

export async function getYupErrors(
  schema: AnySchema,
  data: Record<string, any>,
): Promise<Record<string, string>> {
  try {
    await schema.validate(data, {
      abortEarly: false,
      stripUnknown: true,
    });

    return {};
  } catch (error) {
    if (error instanceof ValidationError) {
      return parseYupError(error);
    }

    return {};
  }
}
