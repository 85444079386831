import { mergeClassNames } from '@hitechline/reactools';
import { useField } from '@unform/core';
import {
  useRef,
  useEffect,
  InputHTMLAttributes,
  DetailedHTMLProps,
} from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

import styles from './styles.module.css';

export type ElementProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface InputProps
  extends Omit<ElementProps | InputMaskProps, 'mask' | 'onBlur'> {
  name: string;
  mask?: InputMaskProps['mask'];
}

export const Input = ({
  name,
  mask,
  className,
  ...rest
}: InputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => inputRef.current?.value ?? '',
      setValue: (_, value) => {
        if (inputRef.current) {
          inputRef.current.value = value;
        }
      },
      clearValue: () => {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className={mergeClassNames(styles.wrapper, className)}>
      <InputMask
        name={name}
        defaultValue={defaultValue}
        mask={mask as any}
        {...rest}
      >
        {(inputProps: InputProps) => (
          <input
            ref={inputRef}
            className="outline-none w-full h-wai bg-floor-white border-2 border-floor-grey-600 rounded-md p-4 focus:border-floor-green"
            {...inputProps}
          />
        )}
      </InputMask>

      {error && (
        <p className="block text-sm mt-1 text-red-500 font-body uppercase">
          {error}
        </p>
      )}
    </div>
  );
};
