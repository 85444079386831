import type { SessionLoginData } from '@hitechline/urbanonorte-types/dtos/franchisee/api';
import { YupObjectSchemaType } from '@hitechline/urbanonorte-types/modules/yup';
import { object, string } from 'yup';

type LoginData = Pick<SessionLoginData, 'email' | 'password'>;

type SchemaType = YupObjectSchemaType<LoginData>;

export const loginSchema = object<SchemaType>({
  email: string()
    .email('Insira um e-mail válido')
    .required('Insira seu e-mail'),
  password: string().required('Insira sua senha'),
});
