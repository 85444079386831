import { Main } from '@/screen/atoms/login/Main';
import { SEO } from '@/screen/components/forward/SEO';

const Login = (): JSX.Element => (
  <>
    <SEO title="Login" />

    <Main />
  </>
);

export default Login;
